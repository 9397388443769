<template>
  <v-row>
    <v-col cols="12" md="6" lg="6" offset-md="3" offset-lg="3">
      <v-card class="elevation-1">
        <v-card-title>
          Stocks
        </v-card-title>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="searchTable"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          dense
        >
          <template #[`item.available`]="{ item }">
            <span>{{ parseInt(item.bought) - parseInt(item.sold) - parseInt(item.use_entry) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'

export default {
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Product Code', align: 'start', value: 'product_code' },
      { text: 'Product Name', align: 'start', value: 'product_name' },
      { text: 'Available Stock', align: 'end', value: 'available', searchable: false },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const listUri = `${process.env.VUE_APP_URI}/api/stockist/stocks`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
    }
  },

  components: {
    snackbar,
  },
}
</script>
